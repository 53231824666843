import './index.css';
import heroVideo from './assets/hero.webm';

function App() {
  return (
    // Main Container
    <div className="App bg-slate-900 min-h-screen">
      {/* Navigation Bar */}
      <div className="Navbar bg-gradient-to-b from-slate-900 to-transparent h-20 relative z-10">
        <div className="container mx-auto text-center justify-center space-x-10 py-6 text-white">
          <a href="" className="font-mono uppercase tracking-widest text-xs">Works</a> 
          <span>//</span>
          <a href="" className="font text-4xl font-corinthia">Jusu</a>
          <span>//</span>
          <a href="" className="font-mono uppercase tracking-widest text-xs">Contact</a>
        </div>
      </div>

      {/* Hero Section with Video Background */}
      <div className="Header -mt-20 relative z-0">
        {/* Video Container */}
        <div className="relative">
          <video
            src={heroVideo}
            autoPlay
            loop
            muted
            className="w-full h-[700px] object-cover"
          />
          {/* Video Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-slate-900 h-full" />
        </div>
        
        {/* Hero Text Overlay */}
        <div className="absolute top-1/2 transform -translate-y-1/2 w-full px-4">
          <div className="container mx-auto">
            <h1 className="text-7xl uppercase font-bold">Hola Amigos!</h1>
            <p className="font-mono">Jusu here. I'm a Motion/Graphic Designer, also IT geeks?</p>
          </div>
        </div>
      </div>

      {/* Portfolio Section */}
      <div className="Video py-5 px-5">
        {/* Section Title */}
        <h5 className="text-center text-white pb-3 font-mono uppercase tracking-widest">
          My Works
        </h5>
        
        {/* Section Divider */}
        <div className="border-t-2 border-gray-500 w-20 mx-auto mb-10" />

        {/* Video Grid */}
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row gap-4">
            {/* Video 1 */}
            <iframe
              src="https://www.youtube.com/embed/50MrNZPc68A?si=HCQXHEPLs_9o9M5I"
              allowFullScreen
              className="w-full md:w-1/2 h-[300px] rounded-lg"
            />
            {/* Video 2 */}
            <iframe
              src="https://www.youtube.com/embed/uy4yExooq3k?si=ZTEAUdVpA-wyR2jo"
              allowFullScreen
              className="w-full md:w-1/2 h-[300px] rounded-lg"
            />
            {/* Video 3 */}
            <iframe
              src="https://www.youtube.com/embed/l03_CKKqkVA?si=QM2ItTpRogSrX8HJ"
              allowFullScreen
              className="w-full md:w-1/2 h-[300px] rounded-lg"
            />
          </div>

          {/* View More Button */}
          <div className="text-center mt-10">
            <button className="border border-white text-white px-8 py-4 rounded-full hover:bg-white hover:text-slate-900 transition-colors duration-300 uppercase tracking-widest text-xs">
              <a href="https://www.youtube.com/playlist?list=PLNtGhcpJwuqJ9vE5e4gM7WXyzsULoA-0U">View More</a>
            </button>
          </div>

        </div>
      </div>
      <div className="border-t-2 border-gray-500 w-1/2 mt-10 mx-auto py-5"></div>
      <div className='Footer'>
        <h1 className='text-white text-center pb-10 uppercase font-mono tracking-widest text-xs'>Made with <span className='font-sans'>❤︎</span> by Lahem</h1>
      </div>
    </div>
  );
}

export default App;
